const init = () => {
  import(/* webpackChunkName: "bootstrap-popover", webpackPrefetch: true */ 'bootstrap/js/dist/popover').then(({ default: Popover }) => {
    document.querySelectorAll('[data-bs-toggle="popover"]').forEach((el) => {
      /* eslint-disable-next-line no-new */
      new Popover(el);
    });
  });
};

export default init;
