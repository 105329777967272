import { Mulish } from 'next/font/google';

const mulish = Mulish({
  subsets: ['latin'],
  preload: true,
  adjustFontFallback: true,
  fallback: ['Trebuchet MS', 'Tahoma', 'Arial'],
  variable: '--mulish-font',
  display: 'swap',
});

const fonts = { mulish };
export default fonts;
