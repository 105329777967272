import Script from 'next/script';

const gtagOff = typeof window !== 'undefined' && /gtag=off/.test(window.location.search);
const key = process.env.GTAG_TRACKING_ID;

const Gtag = () => {
  if (gtagOff) {
    return null;
  }
  return [
    <Script
      key="gtag"
      src={`https://www.googletagmanager.com/gtag/js?id=${key}`}
      strategy="afterInteractive"
    />,
    <Script key="google-analytics" id="google-analytics" strategy="afterInteractive">
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', '${key}');
      `}
    </Script>
  ];
};

export default Gtag;
