// import '../styles/site_no_bs.scss';
import '../styles/site.scss';
import { useMemo } from 'react';

import { useRouter } from 'next/router';

import Gtag from 'components/gtag';
import Layout from 'components/layout/layout';
import logger from 'lib/logger';
import { SessionContext } from 'lib/session';

const log = logger({ category: 'app' });

// config.autoAddCss = false;

// Note can't use destructuring here see:
//   https://nextjs.org/docs/api-reference/next.config.js/environment-variables

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  let content = null;
  log.silly('pageProps: %o', pageProps);
  const sessionData = useMemo(() => ({ }), []);
  if (/\/api/.test(router.asPath)) {
    content = <Component {...pageProps} />;
  } else if (Component.getLayout) {
    if (Component.getLayout === 'snippet') {
      return (
        <SessionContext.Provider value={sessionData}>
          <Component {...pageProps} />
        </SessionContext.Provider>
      );
    }
    log.debug('Component has layout: %o', typeof Component.getLayout);
    content = Component.getLayout(<Component {...pageProps} />, pageProps);
  } else {
    log.debug('Component has no layout');
    content = (
      <main className="ht">
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </main>
    );
  }
  return (
    <>
      <Gtag />
      <SessionContext.Provider value={sessionData}>
        {content}
      </SessionContext.Provider>
    </>
  );
};

export default App;
