import Head from 'next/head';

const defaultMeta = {
  title: 'Untitled Page - grokl.com',
  description: 'Untitled Page',
};

// Note: the TitleAndMeta component from SB will handle the per-page settings
const Meta = ({ story, ...props }) => {
  const canonical = 'https://grokl.com';
  return (
    <Head>
      <title>{props.title || defaultMeta.title}</title>
      <meta charSet="UTF-8" />
      <meta httpEquiv="Content-Language" content="en" />
      <meta name="description" content={props.description || defaultMeta.description} /> {/* will be overridden by title_and_meta */}
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta property="og:site_name" content="grokl" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />

      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <link rel="search" type="application/opensearchdescription+xml" title="grokl" href="/opensearch.xml" />
      <link rel="canonical" href={canonical} />
      {props.rel_prev && (
        <link rel="prev" href={props.rel_prev} />
      )}
      {props.rel_next && (
        <link rel="next" href={props.rel_next} />
      )}

      {/* eslint-disable-next-line react/no-danger */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(props.schema) }} />
    </Head>
  );
};

export default Meta;
