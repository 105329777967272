import Link from 'next/link';

import logger from 'lib/logger';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'footer' });

/* eslint-disable-next-line no-unused-vars */
const FooterLink = ({ link }) => {
  if (link.path === '#') {
    return <a href={link.path} title={link.title} rel={link.rel} className={link.class}>{link.text}</a>;
  }
  return <Link href={link.path} title={link.title} rel={link.rel}>{link.text}</Link>;
};

const Footer = () => (
  <footer className="ht-footer" />
);

export default Footer;
