import { useEffect } from 'react';

import logger from 'lib/logger';
import setupPopovers from 'lib/popovers';
import setupSiteControl from 'lib/site_control';

import fonts from './fonts';
import Footer from './footer';
import Meta from './meta';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'components.layout' });

const Layout = ({ children, ...props }) => {
  useEffect(() => {
    // setupNav();
    setupPopovers();
    setupSiteControl();
  }, []);

  return (
    <>
      <Meta {...props} />
      <div className={`ht-wrapper ${fonts.mulish.className}`}>
        <div className="ht-content">
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
